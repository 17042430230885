"use client";
import classNames from "classnames";
import { HTMLAttributes } from "react";
import { computeIconStyle, IconStyleType } from "..";
import { Shape } from "../../../components/shapes";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { Grid } from "../../blocs";
import { formType } from "../../buttons";
import { IconButtonIcons, IconList } from "../../icons";

type Props = {
  children: any;
  size?: number;
  icon?: IconList | JSX.Element;
  iconStyle?: IconStyleType;
  className?: string;
  form?: formType;
  iconColor?: CssVariablesType;
  shapeColor?: CssVariablesType;
  withShape?: boolean;
  style?: HTMLAttributes<HTMLDivElement>["style"];
};

export function H2({ children, size = 22, icon, iconColor = "text-soft", shapeColor = "blue-background", iconStyle = "info", className = "", withShape = true }: Props) {
  let computed = computeIconStyle(iconStyle);
  const computedShapeColor = computed?.shapeColor ?? shapeColor;
  const computedIconColor = computed?.iconColor ?? iconColor;

  return (
    <Grid
      space={10}
      mediaQuery={0}
      template={icon ? `auto 1fr` : "1fr"}
      alignItems="center"
      className={classNames(!className?.includes("mb") && "mb-1", !className?.includes("mt") && "mt-4", className)}
    >
      {icon && withShape && (
        <Shape form="circle" color={computedShapeColor}>
          {typeof icon == "string" && <IconButtonIcons icon={icon} size={size} color={computedIconColor} />}
          {typeof icon !== "string" && icon}
        </Shape>
      )}

      {icon && !withShape && (
        <>
          {typeof icon == "string" && <IconButtonIcons icon={icon} size={size * 2} color={computedIconColor} />}
          {typeof icon !== "string" && icon}
        </>
      )}

      <h2 className="mt-0 mb-0">{children}</h2>
    </Grid>
  );
}
