import classNames from "classnames";
import { Shape } from "../../../components/shapes";
import { Grid } from "../../blocs";

import { HTMLAttributes } from "react";
import { computeIconStyle, IconStyleType } from "..";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { IconButtonIcons, IconList } from "../../icons";

type Props = {
  children: any;
  size?: number;
  icon?: IconList | JSX.Element;
  iconStyle?: IconStyleType;
  iconColor?: CssVariablesType;
  shapeColor?: CssVariablesType;
  className?: string;
  numero?: number;
  styleText?: any;
  style?: HTMLAttributes<HTMLDivElement>["style"];
  withShape?: boolean;
  form?: "circle" | "square";
};

export function H3({
  children,
  size = 12,
  icon,
  iconColor = "text-soft",
  styleText,
  shapeColor = "blue-background",
  className,
  iconStyle = null,
  numero,
  style,
  withShape = true,
  form = "circle",
}: Props) {
  let computed = computeIconStyle(iconStyle);
  const computedShapeColor = computed?.shapeColor ?? shapeColor;
  const computedIconColor = computed?.iconColor ?? iconColor;

  return (
    <Grid
      style={style}
      space={8}
      mediaQuery={0}
      template={icon || numero ? `auto 1fr` : "1fr"}
      alignItems="center"
      className={classNames(!className?.includes("mb") && "mb-2", !className?.includes("mt") && "mt-2", className)}
    >
      {numero && (
        <div
          style={{
            width: "30px",
            height: "30px",
            background: "var(--color-active)",
            color: "white",
            fontSize: "1.05rem",
            fontWeight: "700",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {numero}
        </div>
      )}
      {icon && withShape && (
        <Shape form={form} color={computedShapeColor}>
          {typeof icon == "string" && <IconButtonIcons icon={icon} size={size} color={computedIconColor} />}
          {typeof icon !== "string" && icon}
        </Shape>
      )}

      {icon && !withShape && (
        <>
          {typeof icon == "string" && <IconButtonIcons icon={icon} size={size * 2} color={computedIconColor} />}
          {typeof icon !== "string" && icon}
        </>
      )}
      <h3 className="mt-0 mb-0 pb-0 pt-0 row a-center" style={styleText}>
        {children}
      </h3>
    </Grid>
  );
}
