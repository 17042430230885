import { useEffect, useState } from "react";
import { Grid } from "ui/components/blocs";
import { H3 } from "ui/components/titles";
import { IoTicketOutline } from "ui/react-icons/io5";
import Item from "./Item";

export default function Support() {
  const [tokenTtl, setTokenTtl] = useState(null);
  useEffect(() => {
    window?.localStorage?.getItem("token_ttl") &&
      setTokenTtl(parseInt(window.localStorage?.getItem("token_ttl")) * 1000);
  }, []);

  return (
    <div className="tile empty mt-05">
      <H3 className="mt-0 mb-1" size={17} icon="documentation">
        Assistance
      </H3>
      <Grid template="1fr 1fr 1fr" space={8} className="mt-2" mediaQuery={768}>
        <Item
          legend="Tickets d'incidents"
          icon={<IoTicketOutline size={25} />}
          url={tokenTtl > Date.now() ? "/informations/tickets" : "/informations/tickets/creer"}
        />
        {/* <Item legend="Demander à être rappelé" icon={<SlEarphonesAlt size={25} />} url="/informations/rappels" /> */}
      </Grid>
    </div>
  );
}
